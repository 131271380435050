import React from 'react';
import Background from './components/background';
import './App.css';

const Main = () => {
  return (
    <Background/>
  );
}

export default Main;
